.program-header{
    display: flex;
    gap: 5rem ;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-size: 3rem;
    justify-content: center;
    font-style: italic;
  
}
.Program{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem ;
}
.program-categories{
    display: flex;
   gap: 1rem;    
}
.category{
    display: flex;
    /* to vertically changes the line */
    flex-direction: column;   
    gap: 1rem;
    background-color: gray;
    color: white;
    justify-content:space-between;
    padding: 2rem;
}
.category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}
.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
    
}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;  
}
.join-now{
    display: flex;
align-items: center;
    gap: 2rem;
}
.join-now>img{
    width: 1rem;
}

.category:hover{
    background: var(--planCard);
    cursor: pointer;
}
@media screen and (max-width:768px){
  .program-header{
    font-size: x-large;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .program-categories{flex-direction: column;}


}