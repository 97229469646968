.hero{
    display: flex;
    justify-content: space-between;
    
}
.left-h{
    padding: 3rem;
    padding-top:1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* z-index: -5;  */
}
.the-best-ad>span{
    z-index: 2; 
}
/* z index of lines is large so it is over the shape or orange color div  */

/* we have to use angular brackets to use child below the parent */
.the-best-ad > .moving-shape {
    position: absolute;
    border-radius: 3rem;
    background-color: var(--orange);
    height: 80%;
    width: 5.4rem;
    left: 8px;
    z-index: 1;
  }

.hero-text{
    text-transform: uppercase;
    display: flex;
    font-weight: bold;
    flex-direction: column;
    color: white;
    font-size: 4.5rem;
    gap: 1.5rem;
    /* below line means that the code itself wrap when screen is small  */
    text-overflow:inherit;  
}
/* To go in the 3rd child of div */
.hero-text>:nth-of-type(3){
 font-size: 1rem;
 font-weight: 200;
 text-transform: none;
 letter-spacing:1px;
 width: 80%;

}
/* in horizontal direction we used flexdir= row and vertical= flexdir-column */
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    font-size: 2rem;
    color: white;

}
.figures>div>span:nth-of-type(2){
    text-transform: uppercase;
    color: var(--gray );
}

.hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-button>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
}
.hero-button>:nth-child(2){
    color: white;
    background-color: transparent;
    border: 2px solid var(--orange);
    width: 8rem;
}

.right-h>.btn{
    position: absolute;
    right:3rem;
    top: 2rem;
    color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:7rem;
    right: 4rem;
    border-radius: 5px ;
    background-color: var(--darkGrey);
    width: fit-content;
    gap: 1rem;
    padding: 1rem;
    align-items: start;

}
.heart-rate>span:nth-of-type(1){
    color: var(--gray);
}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}
.hero-image{
    position: absolute;
    top:10rem;
    right:8rem ;
    width: 23rem;
}
.hero-image-back{
    position: absolute;
    top:4rem;
    right:20rem;
    width: 15rem;
    z-index: -1;
}

.calories {
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top:32rem;
    right: 28rem;
}

.calories >img {
    width: 3rem;
    
}
.calories > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories > div >:nth-child(1){
    color: var(--gray);
}
.calories >div >:nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.hero-blur{
    height: 30rem;
    width: 22rem;
    left: 0;
}

@media screen and (max-width:768px)
{
    .hero {
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .the-best-ad{
        margin-top: 0;
        transform: scale(0.8);
         align-self: center;
        font-size: small;
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        
    }
    .hero-text>div:nth-of-type(3){
        font-weight: 200;
        font-size: small;
        text-align: center;
        letter-spacing: 1px;
    }
    .hero-button{
        justify-content: center;
    }
    .figures > div > span:nth-of-type(1){
        font-size: large;
    }
    .figures > div > span:nth-of-type(2){
        font-size: small;
    }
  .right-h{position: relative;
    background: none;
}
.heart-rate{left: 1rem;top: 2rem;}
.calories{position: relative;
 left: 2rem;
top: 5rem;}

.calories>img{width: 2rem;}
.calories>div>:nth-of-type(2){font-size: 1rem;}

.hero-image{
    position: relative;
    top: 4rem;
    left: 7rem;
    width: 15rem;
    align-self: center;
}

.hero-image-back{
    top: 0rem;
    left: 2rem;
    width: 15rem;
}



}