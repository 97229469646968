.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-j{
    font-size: 3rem;
    color: white ;
    font-weight: bold;
    position: relative;
}
.left-j>hr{
    position: absolute;
    /* yha pr width ke matlab chodai ha or bole to x axis ki height  */
    width: 10.5rem; 
    margin-top: -10px;
    border: 2px solid var(--orange);
    border-radius: 20%;
}
/* to add space btw text */
.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--lightgrayz);
}
::placeholder{color: white;}
.btn-j{background-color: var(--orange);
color: white;
}

@media screen and (max-width:728px){
    .Join{flex-direction: column; gap: 1rem;}
    
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{padding: 2rem;}

    
}

